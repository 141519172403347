@use "sass:math";

@use 'styles/library' as *;

.Balance {

  & .myBalance,
  & .transfer-tokens {
    @include labeledCard;
  }

  & .display,
  & .transfer-amount,
  & .enter-address,
  & .enter-address + div,
  & .select-contact {
    @include label-input-combo;
  }

  & .transfer-amount {
    margin: {
      bottom: $base-padding;
    }

    & > div {
      position: relative;

      & input {
        width: 100%;
      }

      & .KiltToken {
        @extend %centerV;

        position: absolute;
        right: math.div($base-padding, 2);
      }
    }
  }

  & .enter-address + div {

    & label {
      @include baseFont;

      margin: {
        top: 0
      }
      padding: {
        left: 2*$base-padding;
      }
      font-size: 12px;
    }
  }
  & .select-contact {

    & > div {
      margin: {
        top: 0 !important;
      }
    }
  }

  & input {
    @include input;
  }
}