@use "sass:math";

@use 'styles/library' as *;
.Spinner {
  & .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

    & div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: math.div(51, 64) * 100%;
      height: math.div(51, 64) * 100%;
      margin: 8px;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border: {
        width: 8px;
        style: solid;
        color: #fff transparent transparent transparent;
      }

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
