@use "sass:math";

@use 'styles/library' as *;
.Utilities {
  & h1 {
    @include h1("Settings");
  }

  & > section {
    @include labeledCard;
  }

  & button {
    @include button-secondary;

    margin: {
      left: math.div($base-padding, 2);
      bottom: math.div($base-padding, 2);
    }

    &:first-child {
      margin: {
        left: 0;
      }
    }
  }

  & .DevTools {
    & > div {
      & > div {
        margin: {
          top: $base-padding;
        }
      }
    }
  }

  & .versions {
    & > div {
      @include label-input-combo;
    }
  }

  & .debugMode {
    & > div {
      @include checkbox;
    }
  }

  & .reset {
    margin-top: 20px;
  }
}
