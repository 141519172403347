@use "~compass-mixins/lib/compass";

%centerV {
    @include compass.transform(translateY(-50%));
    top: 50%;
}

%centerH {
    @include compass.transform(translateX(-50%));
    left: 50%;
}

%centerHV,
%centerVH {
    @include compass.transform(translateX(-50%) translateY(-50%));
    top: 50%;
    left: 50%;
}
