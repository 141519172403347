@use 'styles/library' as *;

.QuoteView {
  & h1 {
    @include h1('Ctype');
  }
  @include labeledCard;

  & > div {
    @include label-input-combo;
  }
}
