@use 'styles/library' as *;
.ContactPresentation {
  @include presentation;

  & .ui--IdentityIcon {
    position: relative;

    & > .container {
      background: $color-white;
    }

    &:after {
      @extend %centerHV;

      @include favorite;
      @include transition($base-transition);

      position: absolute;
      top: 0;
      left: 100%;
      margin: {
        top: 1px;
        left: -1px;
      }
      font-size: 0;
    }
  }

  &.internal {
    & .ui--IdentityIcon {
      &:after {
        font-size: 12px;
      }
    }
  }
}
