@use 'styles/library' as *;

.WalletView {

  & h1 {
    @include h1('Wallet');
  }

  & table {
    $label-width: 30%;

    width: 100%;

    & td {

      &:first-child {
        width: $label-width;
      }

      &:last-child {
        width: 100% - $label-width;
      }
    }

    & tr {

      &:first-child {

        & td {

          &:last-child {
            @include baseFont-bold;
          }
        }
      }
    }
  }

  & .IdentityView {

    &.selected {
      border: 3px solid $color-primary;

      & h2 {
        color: $color-primary;
      }
    }
  }
}