@mixin placeholder {

    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}
