@use "sass:math";

@use 'styles/library' as *;
.Modal {
  z-index: $z-index-modal;
  display: flex;
  max-width: none;
  margin: 0;
  padding: $base-padding;
  overflow: auto;

  &,
  & .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  & .backdrop {
    z-index: $z-index-modal + 1;
    background: $color-background-backdrop;
  }

  & > .container {
    @include border-radius($base-border-radius);

    position: relative;
    z-index: $z-index-modal + 2;
    width: 100%;
    max-width: 1024px - 2 * $base-padding;
    margin: auto;
    background: $color-white;
    border: $base-border;
    @media (max-width: 500px) {
      height: 25%;
    }
  }

  & header {
    @include baseFont-bold;

    position: relative;
    padding: math.div($base-padding, 2) $base-padding;
    border: {
      bottom: $base-border;
    }
    font-size: 20px;

    & .header-ContactPresentation {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
    }

    & .close {
      @include button-icon-only($icon-times);

      position: absolute;
      margin: 4px;
      top: 0;
      right: 0;
      border: none;
    }
  }

  & .body {
    padding: math.div($base-padding, 2) $base-padding;
    line-height: 1.6;

    & footer {
      margin: $base-padding (-$base-padding) math.div(-$base-padding, 2);
    }
  }

  & footer {
    padding: math.div($base-padding, 2) $base-padding 0;
    text-align: right;
    border: {
      top: $base-border;
    }

    & button {
      @include button-secondary;

      margin: {
        left: math.div($base-padding, 2);
        bottom: math.div($base-padding, 2);
      }

      &.confirm {
        @include button-primary;
      }
    }
  }

  &.small {
    & > .container {
      max-width: 640px;
    }
  }
}
