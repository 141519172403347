@use 'styles/library' as *;
.CTypePresentation {
  @include presentation;

  & > a {
    & .ui--IdentityIcon {
      cursor: pointer;
    }
  }
}
