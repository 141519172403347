@use 'styles/library' as *;

.QuoteCreate {
  @include labeledCard;
  & h1 {
    @include h1('Ctype');
  }
  & > div {
    @include label-input-combo;
  }
}
