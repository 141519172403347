$desktop: 1024px;
$tablet: 480px;

@mixin breakpoint($name, $padding: 0) {
  @if ($name == 'phone-tablet-only') {
    @media (max-width: $desktop - 1px + $padding) {
      @content;
    }
  } @else if ($name == 'phone') {
    @media (max-width: $tablet - 1px + $padding) {
      @content;
    }
  } @else if ($name == 'tablet') {
    @media (min-width: $tablet + $padding) {
      @content;
    }
  } @else if ($name == 'desktop') {
    @media (min-width: $desktop + $padding) {
      @content;
    }
  }
}

@mixin breakpointBelow($breakpointBelow) {
  @media (max-width: $breakpointBelow - 1) {
    @content;
  }
}

@mixin breakpointAbove($breakpointAbove) {
  @media (min-width: $breakpointAbove) {
    @content;
  }
}
