@use 'styles/library' as *;
.MyDelegationsListView {
  & table {
    @include table;
    @include responsiveTable(
      (
        phone: (
          100%,
          false,
          false,
          false,
          false,
          false,
          120px,
        ),
        tablet: (
          false,
          100%,
          70px,
          100%,
          120px,
          false,
          170px,
        ),
        desktop: (
          false,
          100%,
          70px,
          100%,
          120px,
          100%,
          270px,
        ),
      )
    );
  }

  & .permissions {
    text-align: center;
  }

  & .id {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px;
  }

  & .delete {
    @include button-icon-only($icon-trash-o);
  }

  & .invite {
    @include button-icon-only($icon-times);
  }
}
