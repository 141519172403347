@use "sass:math";

@use 'styles/library' as *;
.SelectAttestedClaim {
  @include card;

  & h4 {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  & label {
    @include checkbox;
  }

  & .properties,
  & .attestations {
    display: flex;
    flex-direction: column;
    margin: {
      left: 1.5 * $base-padding;
    }

    & h4 {
      margin: {
        top: $base-padding;
      }

      & label {
        margin: 0;
      }
    }

    & label {
      margin: {
        top: math.div($base-padding, 2);
      }

      &.selected-all {
        @include opacity(0.3);
      }
    }
  }

  & .actions {
    margin: {
      top: $base-padding;
    }

    & button {
      @include button-primary;
    }
  }

  & .AttestationStatus {
    display: inline-block;
    width: 30px;

    &.unverified {
      & + .ContactPresentation {
        color: $color-danger;
        text-decoration: line-through;
      }
    }

    &.attested {
      & + .ContactPresentation {
        color: $color-success;
      }
    }
  }
}
