@use 'styles/library' as *;

.MyQuotesList {
  @include labeledCard;
  & h1 {
    @include h1('Ctype');
  }
  & > div {
    @include label-input-combo;
  }
  & table {
    @include table;
    @include responsiveTable(
      (
        phone: (
          100px
          false,
          100px
          30px,

          getActionTdWidth(2),
        ),
        tablet: (
          100px
          false,
          100px
          50px,

          getActionTdWidth(2),
        ),
        desktop: (
          100px,
          100px,
          300px,
          50px,
          getActionTdWidth(2),
        ),
      )
    );
  }
  & .delete {
    @include button-icon-only($icon-trash);
  }
  
}
