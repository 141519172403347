@use "sass:math";

@use 'styles/library' as *;

.Notifications {
  $z-index: $z-index-header + -1;

  $notification-width: 400px;

  position: fixed;
  z-index: $z-index;
  top: $header-height;
  right: 0;
  max-width: $notification-width + 2 * $base-padding !important;
  max-height: calc(100% - #{$header-height});
  padding: 0 0 math.div($base-padding, 2) 0 !important;
  overflow: auto;

  & header {
    @include baseFont-bold;

    display: flex;
    align-items: center;
    margin: {
      bottom: math.div($base-padding, 2);
    }
    font-size: 12px;

    &:before {
      margin: {
        right: math.div($base-padding, 2);
      }
    }
  }

  & .notification {
    @include card;
    @include console-log;

    position: relative;
    display: block;
    margin: math.div($base-padding, 2) math.div($base-padding, 2) 0 0;
    padding: math.div($base-padding, 2);
    background: white;
    font-size: 14px;

    & .body {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    &.SUCCESS {

      & header {
        color: $color-success;

        &:before {
          @include icon($icon-check-circle-o);

          font-size: 16px;
        }
      }
    }

    &.FAILURE {

      & header {
        color: $color-danger;

        &:before {
          @include icon($icon-warning)
        }
      }
    }
  }

  & .close {
    @include button-icon-only($icon-times);

    position: absolute;
    top: 0;
    right: 0;
    border: none;
  }
}