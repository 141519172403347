@use 'styles/library' as *;
.MessageSubject {
  & .type {
    @include baseFont-bold;
  }

  & > div {
    @extend %ellipsis;
  }
}
