@use 'styles/library' as *;
.SelectAttestedClaims {
  & .cType-container {
    @include labeledCard;

    &:last-child {
      margin: {
        bottom: $base-padding;
      }
    }
  }
}
