@use 'styles/library' as *;
.AttestedClaimVerificationView {
  @include labeledCard(1);

  & .refresh {
    @include button-icon-only($icon-refresh);
  }

  & h2 {
    & .status {
      font-size: 16px;
    }

    & .Spinner {
      display: inline-block;
    }
  }

  & .attributes {
    & > div {
      @include label-input-combo;
    }
  }

  & .attesterName {
    width: 60%;
  }

  & .AttestationStatus {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
  }

  & .attestationStatusView {
    position: relative;

    & > * {
      position: absolute;
    }
  }
}
