@use 'styles/library' as *;
.CtypeDetailView {
  & .attributes {
    @include card;

    position: relative;

    & > div {
      @include label-input-combo;
    }

    & .CTypePresentation {
      @include detailPresentation;
    }
  }
}
