@use "sass:math";

@use 'styles/library' as *;
.MessageListView {
  & table {
    @include table;
    @include responsiveTable(
      (
        phone: (
          "*",
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          getActionTdWidth(2),
        ),
        tablet: (
          false,
          "*",
          false,
          false,
          false,
          false,
          false,
          110px,
          getActionTdWidth(2),
        ),
        desktop: (
          false,
          false,
          40%,
          "*",
          40%,
          110px,
          110px,
          false,
          getActionTdWidth(2),
        ),
      )
    );
  }

  & th,
  & td {
    text-align: left;
  }

  & th {
    @extend %ellipsis;
  }

  & .delete {
    @include button-icon-only($icon-trash-o);
  }

  & .open {
    @include button-icon-only($icon-eye);
  }

  & .subject {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: $color-primary;
    }
  }

  & .invalid {
    &:before {
      @include icon($icon-warning);

      color: $color-danger;
      margin: {
        right: math.div($base-padding, 4);
      }
    }
  }

  @include breakpoint(phone-tablet-only) {
    & td {
      padding-bottom: $base-padding;
    }
  }
}
