@use "sass:math";

@use 'styles/library' as *;
.react-json-view {
  padding: math.div($base-padding, 2);
  line-height: 1.2;
  font-size: 16px;
  overflow: auto;
}

.Code {
  max-height: 400px;
  margin: 0;
  padding: 20px;
  background: black;
  color: white;
  overflow: auto;
}
