@use "sass:color";
// BASE COLORS
$color-black: #000;
$color-white: #fff;
$color-grey: #DFE3E9;
$color-grey-dark: #5C5D5D;
$color-grey-light: #f2efee;
$color-grey-icon: $color-grey-dark;
$color-light-blue: #c8e3ee;
$color-green: #5ab9aa;
$color-purple: #4f405c;

// BACKGROUNDS
$color-background: #f2efee;
$color-background-backdrop: color.adjust($color-black, $alpha: -0.5);

// PRIMARY
$color-primary: #ef5a28;
$color-secondary: #8c145a;

// DANGER
$color-danger: #be1e23;

// SUCCESS
$color-success: #3AB93C;

// FONT-COLORS
$color-font: $color-grey-dark;
$color-font-inverted: $color-white;

// HIGHLIGHTS
$highlight-active: color.adjust($color-primary, $alpha: -0.3);
$highlight-related: color.adjust($color-primary, $alpha: -0.8);
