@use "sass:math";

@use 'styles/library' as *;

.ContactList {

  & h1 {
    @include h1('Contact');
  }

  & table {
    @include table;
    @include responsiveTable(
        (
          phone: ('*', 120px),
          tablet: ('*', 170px),
          desktop: ('*', 270px),
        )
    );
  }

  & .contactActions{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: {
      bottom: $base-padding;
    }

    & button {
      margin:{
        left: math.div($base-padding, 2);
      }
    }
  }

  & .toggleContacts {
    @include button-primary;
  }

  & .refresh {
    @include button-icon-only($icon-refresh);
  }

  & .noContactsMessage {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button {
      @include button-secondary;
    }
  }
  & .importViaDID {
    @include labeledCard;

    & > div:not(.actions) {
      @include label_input_combo;

      & input {
        width: 100%;
      }
    }
  }
}
  