@use "sass:math";

@use 'styles/library' as *;

.AttestationsView {

  & h1 {
    @include h1('Claim-attested');
  }

  & .revokeByHash {
    @include labeledCard;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & input {
      flex-grow: 1;
      margin: {
        right: math.div($base-padding, 2);
      }
    }

    & button {
      @include button-secondary;
    }
  }

  & table {
    @include table;
    @include responsiveTable(
        (
          phone: ('*', false, '*', false, 50px, getActionTdWidth(2)),
          tablet: ('*', false, '*', 100px, 50px, getActionTdWidth(2)),
          desktop: ('*', 200px, '*', 100px, 80px, getActionTdWidth(2)),
        )
    );
  }

  & .claimerAlias {
    @extend %ellipsis;
  }

  & .claimHash {
    word-break: break-word;
  }

  & .revoke {
    @include button-icon-only($icon-ban);
  }

  & .delete {
    @include button-icon-only($icon-trash);
  }
}