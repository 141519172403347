@use 'styles/library' as *;

.CTypeCreate {

  & h1 {
    @include h1('Ctype');
  }

  & .Ctype-name {
    @include card;
    @include label-input-combo;

    margin: {
      bottom: -$base-border-width;
    }
    padding: $base-padding;
  }
}