@use "sass:math";

@use 'styles/library' as *;
.schema-based-json-editor {
  width: 100%;
  margin: {
    right: auto;
    bottom: $base-padding;
    left: auto;
  }

  & .hidden {
    display: none !important;
  }

  & h3,
  &--card {
    flex-shrink: 0;
    width: 100%;
    margin: auto;
  }

  & h3 {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: {
      top: $base-padding;
      bottom: math.div($base-padding, 2);
    }
    padding: {
      left: math.div($base-padding, 2);
    }
    color: $color-font;
  }

  &--card {
    @include card;

    & .schema-based-json-editor {
      &--row,
      &--error-row {
        @include label-input-combo;

        margin-top: $base-padding;

        &:first-child {
          margin-top: 0;
        }

        & label {
          & > .schema-based-json-editor--button-group {
            @include baseFont;
          }
        }
      }

      &--description {
        color: $color-primary;
        font-size: 12px;
        text-align: right;
        width: 100%;
        margin: 4px 0 0 0;
      }
    }

    &:empty {
      display: none;
    }

    &:first-child {
      & h3 {
        margin: {
          top: 0;
        }
      }
    }
  }

  &--card > &--card {
    @include box-shadow(none);

    padding: 0;
    border: none;
  }

  &--button {
    @include button-icon-only-i;

    margin: {
      left: math.div($base-padding, 2);
    }
    border: none;

    // expand
    & .fa-caret-square-down {
      &:before {
        @include icon($icon-toggle-down);
      }
    }

    // collapse
    & .fa-caret-square-right {
      &:before {
        @include icon($icon-caret-square-o-right);
      }
    }

    // collapse
    & .fa-plus {
      &:before {
        @include icon($icon-plus);
      }
    }

    // delete
    & .fa-times {
      &:before {
        @include icon($icon-times);
      }
    }
  }

  & > &--row {
    & > h3 {
      display: none;
    }
  }
}
