@use "sass:math";
@use "styles/library/variables" as *;

@mixin presentation {

  &,
  & > a {
    @include baseFont;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 16px;
  }

  & .container {

    &:before {
      display: none;
    }
  }

  & .ui--IdentityIcon {
    cursor: default;
  }

  & .ui--IdentityIcon,
  & .label {
    display: inline-block;
    vertical-align: middle;
  }

  & .label {
    @extend %ellipsis;

    @include baseFont;

    padding:{
      right: math.div($base-padding, 4);
      left: math.div($base-padding, 4);
    }
    font-size: 16px;

    & small {
      @include baseFont;

      font-size: 0.6em;
      margin-left: 0.4em;
    }
  }

  &.inline {
    display: inline-flex;
    margin-left: math.div($base-padding, 2);
    vertical-align: middle;
  }

  &.withActions {
    $fullSizeActionWidth: 170px;

    position: relative;
    padding: {
      right: $base-button-height;
    }

    & .label {
      padding: {
        right: 0;
      }
    }

    & .SelectAction {
      @include react-select-left;
      @include react-select-min-width;

      position: absolute;
      right: 0;

      &.fullSize {
        width: $fullSizeActionWidth;
      }
    }

    &.fullSizeActions {
      min-height: $base-button-height;
      padding: {
        right: $fullSizeActionWidth - $base-button-height + math.div($base-padding, 2);
      }
    }

    &.alignRight {

      & .SelectAction {
        @include react-select-right;
      }
    }
  }
}

@mixin detailPresentation {
  @extend %centerV;

  @include border-radius(50%);

  position: absolute;
  top: 0;
  right: math.div($base-padding, 2);
  padding: 0 !important;
  border: 5px solid $color-white;

  & .label {
    display: none;
  }
}

@mixin invertedPresentation {
  @include react-select-minimal-inverted;

  & .container {
    $borderWidth: 1px;

    position: relative;
    background: $color-font-inverted;
  }

  & .label {
    color: $color-font-inverted;
  }
}