@use 'styles/library' as *;

.SubmitTerms {

  & .selectTerms,
  & .selectDelegation,
  & .preFillClaim {
    @include labeledCard;
  }

  & .container-actions {

    & button {
      @include button-secondary;
    }
  }
}