@use 'styles/library' as *;

.IdentitySelector {
  @include react-select;
  @include react-select-right;
  @include react-select-max-width;

  & .react-select {

    &-container {
      width: 200px;
    }

    &__menu-list {
      max-height: calc(100vh - #{$header-height});
    }

    &__value {

      &-container {
        overflow: visible;
      }
    }

    &__option {
      white-space: nowrap;

      &:last-child {
        border-top: $base-border;
      }
    }
  }
}