$darken: 5;

@mixin button-base {
  @include baseFont;
  @include border-radius($base-border-radius);

  display: inline-block;
  height: $base-button-height;
  line-height: $base-button-height;
  text-align: center;
  padding: 0 $base-padding;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;

  &[disabled] {
    @include opacity(0.3);

    cursor: not-allowed;
  }

  &:not([disabled]){

    &:hover,
    &:active {
      background: $color-grey;
      color: $color-font;
      border-color: $color-grey;
    }
  }
}

// NORMAL BUTTONS
@mixin button {
  @include button-base;
  @include transition($base-transition);

  min-width: 150px;
  padding: 0 2*$base-padding;
  border: $base-border;
  font-size: $base-font-size;
}

@mixin button-primary {
  @include button;

  background: $color-primary;
  color: white;
  border: none;
}

@mixin button-secondary {
  @include button;

  border-color: $color-primary;
  color: $color-primary;
  background: $color-white;
}

// ICON BUTTONS
$base-icon-size: $base-font-size;

@mixin button-icon-only-base($icon-size: $base-icon-size) {
  @include button-base;
  @include transition($base-transition);

  position: relative;
  width: $base-button-height;
  min-width: auto;
  padding: 0;
  background: none;
  color: $color-primary;
  font-size: $icon-size;
  line-height: 0;
  border: $base-border-width solid $color-primary;
}

@mixin button-icon-only($icon, $icon-size: $base-icon-size) {
  @include button-icon-only-base($icon-size);

  &:before {
    @extend %centerHV;

    @include icon($icon);

    position: absolute;
  }
}

@mixin button-icon-only-i($icon-size: $base-icon-size) {
  @include button-icon-only-base($icon-size);

  & i {
    @extend %centerHV;

    position: absolute;
    font-size: $icon-size;
  }
}

@mixin button-icon-only-inline($icon) {
  $icon-size: 14px;
  $button-size: 24px;
  @include button-icon-only($icon, $icon-size); 

  min-width: $button-size;
  padding: 0px;
  width: $button-size;
  height: $button-size;
}


// MISC BUTTONS