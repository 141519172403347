@use "sass:math";

@use 'styles/library' as *;
.DelegationDetailView {
  & .delegationNodeContainer {
    @include labeledCard;

    width: 100%;
  }

  & .delegationNodeScrollContainer {
    display: inline-block;
    width: 100%;
    padding: {
      right: math.div($base-padding, 4);
      bottom: 8 * $base-button-height;
      left: math.div($base-padding, 4);
    }
    overflow: auto;

    & > .DelegationNode {
      &:before,
      &:after {
        display: none;
      }
    }
  }

  & .viewTypeLabel {
    position: absolute;
    top: math.div($base-padding, 4);
    right: math.div($base-padding, 2);
    font-size: 10px;
    color: $color-grey;
  }
}
