@use 'styles/library' as *;

.RequestTerms {

  & .requestTerms {
    @include button-primary;
  }

  & .selectClaims {
    @include labeledCard;
  }
}