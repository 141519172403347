@use 'styles/library' as *;
.MyClaimListView {
  & table {
    @include table;
    @include responsiveTable(
      (
        phone: (
          100%,
          100%,
          30px,
          120px,
        ),
        tablet: (
          100%,
          100%,
          100px,
          170px,
        ),
        desktop: (
          100%,
          100%,
          100px,
          270px,
        ),
      )
    );
  }

  & .deleteClaim {
    @include button-icon-only($icon-trash);
  }
}
