@use "sass:math";

@use 'styles/library' as *;
.Footer {
  position: fixed;
  z-index: $z-index-header;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: none !important;
  border-top: $base-border;
  padding: math.div($base-padding, 4) !important;
  background: $color-white;
  text-align: center;
  font-size: 12px;

  & a {
    margin: 0 $base-padding;
  }
}
