@use "sass:math";

@use 'styles/library' as *;
.Header {
  position: fixed;
  z-index: $z-index-header;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background: $color-purple;

  & > section {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  & .menu {
    @include button-icon-only($icon-navicon, 20px);

    margin: {
      right: math.div($base-padding, 2);
    }
    color: $color-white;

    &:hover,
    &:focus,
    &:active {
      color: $color-white;
    }
  }

  & .logo-id {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;

    & > * {
      margin-left: math.div($base-padding, 2);
    }
  }

  & .logo {
    display: block;
    height: 30px;

    & img {
      height: 100%;
      width: auto;
    }
  }

  &.open-navigation {
    & .Navigation {
      display: block;
    }
  }

  @include breakpoint("phone-tablet-only") {
    & .Navigation {
      $icon-size: 24px;

      position: fixed;
      z-index: $z-index-header + 1;
      display: none;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;

      & ul {
        list-style-type: none;
        width: 100%;
        margin: 0;
        padding: $header-height 0 0;
        overflow: auto;
      }

      &:before {
        content: "";
        position: absolute;
        top: $header-height;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparentize($color-background, 0.9);
      }

      & li {
        position: relative;
        display: block;
        max-width: $tablet;
        background: $color-purple;
        text-align: left;
        border: {
          right: $base-border;
          top: $base-border;
        }

        &.current {
          & a {
            background: transparent;
            color: $color-primary;
          }
        }

        & a {
          @include ellipsis;

          display: flex;
          justify-content: left;
          align-items: center;
          width: 100%;
          height: $base-input-height;
          padding: 0 $base-padding;
          line-height: $base-input-height;
          color: $color-white;
          text-decoration: none;

          &:hover,
          &:focus,
          &:active {
            background: $color-primary;
            color: $color-white;
          }

          &:before {
            content: "";
            display: block;
            width: $icon-size;
            height: $icon-size;
            margin-right: math.div($base-padding, 2);
            background: {
              position: center;
              repeat: no-repeat;
              size: contain;
            }
          }
        }

        $icons: (
          dashboard: "Identity",
          attestations: "Claim-attested",
          wallet: "Wallet",
          ctype: "Ctype",
          claim: "Claim",
          utilities: "Settings",
          contacts: "Contact",
          messages: "Message",
        );

        @each $class, $icon in $icons {
          @if ($icon != "") {
            &.#{$class} {
              & a {
                &:before {
                  background: {
                    image: url("../../assets/#{$icon}.svg");
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint("desktop") {
    & .menu {
      display: none;
      pointer-events: none;
    }

    & .Navigation {
      display: block !important;

      & ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      & li {
        display: inline-block;

        &.current {
          & a {
            color: $color-primary !important;
          }
        }

        & a {
          padding: math.div($base-padding, 4) 0;
          margin: {
            right: $base-padding;
          }
          color: $color-white;
          text-decoration: none;

          &:hover,
          &:focus,
          &:active {
            color: $color-primary;
          }
        }
      }

      & .testUserFeedback,
      & .chain-stats {
        display: none;
      }
    }
  }
}

.debugModeLabel {
  $fontSize: 12px;
  $margin: math.div($base-padding, 4);

  @include transform-origin(left, top);
  @include transform(rotate(-90deg));

  position: fixed;
  top: 100%;
  left: 100%;
  margin: {
    top: -$margin;
    left: -$fontSize - $margin;
  }
  color: $color-danger;
  font-size: $fontSize;
  white-space: nowrap;
}
