@use "sass:math";

@use 'styles/library' as *;
.MessageDetailView {
  & h4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .toggle-code {
    @include button-icon-only($icon-code);
  }

  & footer {
    & .delete {
      @include button-primary;
    }
  }

  & .code-tabs {
    display: flex;
    justify-content: left;
    align-items: flex-end;
    font-size: 12px;

    & > * {
      @include border-radius($base-border-radius $base-border-radius 0 0);
      border: {
        width: 1px 1px 0 1px;
        style: solid;
        color: $color-grey-light;
      }
      padding: math.div($base-padding, 4) math.div($base-padding, 2);
    }
  }

  & .code {
    @each $message in encrypted, decrypted {
      &.#{$message} {
        & .#{$message} {
          border: {
            color: $color-grey-dark;
          }
          background: $color-grey-dark;
          color: $color-white;
        }
      }
    }
  }
}
