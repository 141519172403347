@use 'styles/library' as *;
.CtypeListView {
  & table {
    @include table;
    @include responsiveTable(
      (
        phone: (
          "*",
          false,
          false,
        ),
        tablet: (
          false,
          "*",
          "*",
        ),
        desktop: (
          false,
          "*",
          "*",
        ),
      )
    );
  }

  @include breakpoint(phone-tablet-only) {
    & td {
      padding-bottom: $base-padding;
    }
  }
}
