@use 'styles/library' as *;
.SelectAction {
  @include react-select;

  &.minimal {
    @include react-select-minimal;

    &.inverted {
      @include react-select-minimal-inverted;
    }
  }

  @for $i from 1 through 20 {
    &.groupAt-#{$i} {
      & .react-select__option {
        &:nth-child(#{$i + 1}) {
          border-top: $base-border;
        }
      }
    }
  }
}
