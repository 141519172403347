@use 'styles/library' as *;
.IdentityView {
  position: relative;
  padding: $base-padding;
  margin: {
    bottom: 2 * $base-padding;
  }

  & .attributes {
    & > div {
      @include label-input-combo;
    }
  }

  &.selected {
    @include labeledCard;
  }

  &:not(.selected) {
    @include card;
  }

  & .ContactPresentation {
    @include detailPresentation;

    &.internal {
      & .ui--IdentityIcon {
        &:after {
          font-size: 16px;
          margin: {
            top: 4px;
            left: -4px;
          }
        }
      }
    }
  }

  & .did {
    margin-right: 10px;
  }

  & .instruction {
    margin: {
      top: $base-padding;
      bottom: 2 * $base-padding;
    }
  }

  & .mailContent {
    @include label-input-combo-list;

    margin: {
      bottom: $base-padding;
    }

    & label + div {
      @include border-radius($base-border-radius);

      padding: $base-padding;
      border: $base-border;
    }
  }

  & button {
    &.QRCodeToggle {
      @include button-secondary;
    }
  }

  & .QRCode {
    animation: appear $base-transition-time;
    margin-top: $base-padding;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
