@use "sass:math";

@use 'styles/library' as *;

.BlockUi {
  $z-index: $z-index-modal + 100;

  position: fixed;
  z-index: $z-index;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: none !important;
  padding: 0 !important;

  & .backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $color-background-backdrop;
  }

  & .container {
    @extend %centerHV;

    @include transform(translateX(-50%) translateY(-100%));

    position: absolute;
    margin: {
      top: -40px;
    }
    color: white;
    text-align: center;
  }

  & .blockUi {
    position: relative;
    margin-bottom: $base-padding;
    font-size: 14px;
    white-space: pre-wrap;
  }

  & header {
    @include baseFont-bold;

    position: relative;
    display: inline-block;
    font-size: 16px;

    &:after {
      content: '';
      position: absolute;
      display: block;
      left: 100%;
      bottom: 0;
      margin: {
        left: math.div($base-padding, 4);
      }
      animation: dotdotdot 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      white-space: nowrap;
      letter-spacing: 3px;
    }
  }

  & .Spinner {
    @extend %centerHV;

    position: fixed;
  }
}

@keyframes dotdotdot {
  0% {
    content: ''
  }
  25% {
    content: '.'
  }
  50% {
    content: '..'
  }
  75% {
    content: '...'
  }
}