@use "sass:math";

@use 'styles/library' as *;
@use "styles/select2-overrides" as *;

@use "select2-component/dist/select2.min.css";
@import 'https://fonts.googleapis.com/css?family=Montserrat:400,600&subset=latin';
@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?qxwohx');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?qxwohx#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?qxwohx') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?qxwohx') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?qxwohx##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

// base elements
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  @include baseFont;

  margin: 0;
  padding: {
    top: $header-height;
    right: 0;
    bottom: 0;
    left: 0;
  }
  background: $color-white;
  color: $color-font;
}

.App {
  padding: {
    bottom: 26px;
  }

  &,
  & > header {

    & > section {
      width: 100%;
      max-width: $base-content-max-width;
      margin: auto;
      padding: $base-padding;

      & h1,
      & h2,
      & h3,
      & h4,
      & h5,
      & h6 {

        &:first-child {
          margin: {
            top: 0;
          }
        }
      }
    }
  }
}

h1 {
  @include baseFont-bold;

  display: flex;
  align-items: center;
  width: 100%;
  height: $h1-icon-size;
  margin: {
    top: $base-padding;
    bottom: 4*$base-padding;
  }
  font-size: 30px;
}

// base form elements
input {

  &[type=text],
  &[type=password],
  &[type=uri-reference] {
    @include input;
  }
}

.actions {
  @include actions;

  padding: {
    top: $base-padding;
  }

  & button,
  & a {
    @extend %ellipsis;

    @include button-secondary;

    margin: {
      left: math.div($base-padding, 2);
      bottom: math.div($base-padding, 2);
    }

    section > & {

      &:last-child {
        @include button-primary;
      }
    }
  }
}

.react-select-container {
  @include react-select;
}

table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  & th {

    &.actions {
      text-align: right;
    }
  }

  & td {
    padding: 0;
  }
}

a[href],
.eml.linked {
  color: $color-primary;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.date-time {
  $time-size: 0.8em;
  $line-height: 1.2;

  position: relative;
  display: inline-block;
  margin-bottom: $time-size * $line-height;

  & small {
    position: absolute;
    right: 0;
    bottom: -$time-size * $line-height;
    padding-left: math.div($base-padding, 4);
    font-size: $time-size;
  }
}