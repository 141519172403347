@use 'styles/library' as *;

.ImportDelegation {

  & .Delegation-base {
    @include labeledCard;

    & input {
      width: 100%;
    }
  }

  & .DelegationDetailView {
    @include labeledCard;
  }
}