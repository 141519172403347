@use "sass:math";
@use "styles/library/variables" as *;

@mixin baseFont {
  font-family: 'Montserrat', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

@mixin baseFont-bold {
  font-family: 'Montserrat', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

@mixin icon($icon) {
  content: $icon;
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin label {
  @include baseFont-bold;

  color: $color-font;
  text-align: left;
}

@mixin label-input-combo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0;

  & > label {
    $breakpoint: 600px;

    @include label;

    margin: {
      top: 8px;
    }

    &,
    & + * {
      width: 100%;
    }

    & + div {
      word-break: break-word;
    }

    & + input {
      @include input;
    }

    & + div {

      margin: {
        top: 8px;
      }
    }

    @include breakpointAbove($breakpoint) {
      $input-width: 70%;

      width: 100% - $input-width;

      & + * {
        width: $input-width;
      }
    }
  }
}

@mixin label-input-combo-list {

  & > * {
    @include label-input-combo;

    margin: {
      bottom: $base-padding;
    }

    &:last-child {
      margin: {
        bottom: 0;
      }
    }
  }
}

@mixin console-log {

  & .console-log {
    display: none;
  }

  &.console-log {

    & .console-log {
      display: block;
      margin: {
        top: 10px;
      }
      font-size: 10px;
    }
  }
}

@mixin actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  clear: both;

  & button,
  & a {
    margin: {
      left: math.div($base-padding, 2);
      bottom: math.div($base-padding, 2);
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

@mixin detailViewInMessage {

  margin:{
    bottom: $base-padding;
  }

  & h1 {
    font-size: 24px;
    margin:{
      top: 2*$base-padding !important;
      bottom: -$base-padding;
    }

    &:before {
      display: none;
    }
  }
}

@mixin favorite {
  @include icon($icon-star);

  color: yellow;
  text-shadow: 0 0 1px black;
}