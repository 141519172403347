@mixin h1 ($icon) {

  &:before {
    content: '';
    width: $h1-icon-size;
    height: $h1-icon-size;
    margin:{
      right: $base-padding;
    }
    background: {
      image: url('../../../assets/#{$icon}.svg');
      size: contain;
      position: center;
      repeat: no-repeat;
    }
  }
}