@use "sass:color";
@use "styles/library/variables" as *;

@mixin react-select {

  & input[type=text] {
    height: 20px;
  }

  & .react-select {

    &__indicator {

      & svg {
        fill: $color-primary;
      }
    }

    &__menu {
      width: auto;
      display: inline-block;

      &-list {
        max-height: 400px;
      }
    }

    &__single-value {
      height: $base-button-height;
      padding: {
        top: 8px;
      }
    }

    &__control {
      @include box-shadow(none !important);

      border: $base-border !important;
      cursor: pointer;
    }

    &__option {
      background: $color-white !important;
      color: $color-font !important;
      cursor: pointer;
      white-space: nowrap;

      &--is-selected {
        background: color.adjust($color-primary, $alpha: -0.8) !important;
      }

      &--is-focused,
      &:hover {
        background: $color-primary !important;
        color: $color-font-inverted !important;
      }
    }

    &__multi-value{

      &__remove {
        color: $color-primary;

        &:hover {
          background-color: $color-primary !important;
          color: $color-font-inverted !important;
        }
      }
    }
  }

  & .ContactPresentation {
    display: initial;
  }
}

@mixin react-select-minimal {

  & .react-select {

    &__indicator {
      position: relative;
      width: $base-button-height;
      height: $base-button-height;

      &:before {
        $size: 16px;

        @extend %centerHV;

        @include border-radius($base-border-radius);
        @include transition($base-transition);

        content: '';
        position: absolute;
        width: $size;
        height: $size;
        border: 1px solid $color-primary;
      }

      & svg {
        @include transition($base-transition);
        @include transform(scale(0.6));
      }

      &:hover,
      &:active {

        &:before {
          background: $color-primary;
        }

        & svg {
          fill: $color-white;
        }
      }
    }

    &__value-container,
    &__indicator-separator{
      @include opacity(0);

      padding: 0;
    }

    &__control {
      border: none !important;
      background: none;
    }
  }
}

@mixin react-select-minimal-inverted {

  & .react-select {

    &__indicator {

      &:before {
        border-color: $color-font-inverted;
      }

      & svg {
        fill: $color-font-inverted;
      }

      &:hover,
      &:active {

        &:before {
          background: $color-font-inverted;
        }

        & svg {
          fill: $color-primary;
        }
      }
    }
  }
}

@mixin react-select-right($right: 0) {

  & .react-select__menu {
    left: auto;
    right: $right;
  }
}

@mixin react-select-left($left: 0) {

  & .react-select__menu {
    right: auto;
    left: $left;
  }
}

@mixin react-select-min-width($minWidth: 200px) {

  & .react-select__menu {
    min-width: $minWidth;
  }
}

@mixin react-select-max-width($maxWidth: 100vw) {

  & .react-select__menu {
    max-width: $maxWidth;
  }
}