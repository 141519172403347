@use "styles/library/variables" as *;

@mixin defaultScheme {

  & > td {
    background: $color-grey-light;

    &:after {
      @include background-image(linear-gradient(to right, transparent, $color-grey-light));
    }
  }
}

@mixin contrastScheme {

  & > td {
    background: $color-white;

    &:after {
      @include background-image(linear-gradient(to right, transparent, $color-white));
    }
  }
}

@mixin table {
  position: relative;
  table-layout: fixed;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    border: 1px solid $color-grey;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
  }

  & thead {
    border: {
      bottom: 1px solid $color-grey;
    }
  }

  & th,
  & td {
    position: relative;
    padding: {
      top: $cell-padding-v;
      bottom: $cell-padding-v;
      right: $cell-padding-h;
      left: $cell-padding-h;
    }
    border: {
      right: $base-border;
    }
    text-align: left;
    vertical-align: top;

    &:last-child {
      border: {
        right: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: $cell-padding-h;
    }
  }

  & th {
    @extend %ellipsis;

    @include baseFont-bold;

    padding: $cell-padding-h;
    background: $color-grey-light;
    color: $color-purple;
  }

  & td {
    vertical-align: middle;
  }

  & > tbody {

    & > tr {
      @include defaultScheme;

      &:nth-child(odd) {
        @include contrastScheme;
      }
    }

    &:nth-child(odd) {

      & > tr {
        @include contrastScheme;

        &:nth-child(odd) {
          @include defaultScheme;
        }
      }
    }

    &.opened {

      &:nth-child(odd),
      &:nth-child(even) {

        & > tr {
          @include contrastScheme;

          &:nth-child(odd) {
            @include defaultScheme;
          }
        }
      }
    }
  }

  & .actionsTd {
    @include react-select-right;
    @include react-select-min-width;

    padding: {
      right: $cell-padding-v;
    }

    & > div {
      padding: 0;
    }

    & > div {
      @include actions;
    }

    & .SelectAction,
    & .SelectDelegationAction {
      width: 100%;
    }
  }

  & .status {
    text-align: center;
    width: 100px;

    &.revoked {

      &:before {
        @include icon($icon-times);

        color: $color-danger;
      }
    }

    &.attested {

      &:before {
        @include icon($icon-check);

        color: $color-success;
      }
    }
  }
}