@use 'styles/library' as *;

.AcceptDelegation {
  @include labeledCard;

  & .delegationData {

    & > div {
      @include label-input-combo;

      margin-bottom: $base-padding;
    }

    & .Permissions {
      font-size: 18px;
    }
  }

  & .danger {
    color: $color-danger;
  }

  & .DelegationDetailView {
    @include detailViewInMessage;
  }
}