@use "sass:math";

@use 'colors';

// MARGIN / PADDING
$base-padding: 20px;
$cell-padding-h: math.div($base-padding, 2);
$cell-padding-v: math.div($base-padding, 4);

// SIZES
$base-content-max-width: 1024px;
$base-input-height: 36px;
$base-button-height: $base-input-height;
$header-height: 60px;

// TRANSITION
$base-transition-time: 0.2s;
$base-transition: all $base-transition-time ease-in-out;

// BORDER
$base-border-radius: 4px;
$base-border-width: 1px;
$base-border: $base-border-width solid colors.$color-grey;

// MISC
$z-index-content: 1;
$z-index-header: 100;
$z-index-modal: 1000;
$h1-icon-size: 50px;