@use 'styles/library' as *;
.MyClaimCreateView {
  & h1 {
    @include h1("Claim");
  }

  & .Claim-base {
    @include card;

    & > div {
      @include label-input-combo;

      margin: {
        bottom: -$base-border-width;
      }
      padding: $base-padding;
    }
  }
}
